var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aForm',{attrs:{"form":_vm.customerForm},on:{"submit":function($event){$event.preventDefault();return _vm.submitCustomer.apply(null, arguments)}}},[_c('aRow',{attrs:{"gutter":20}},[_c('aCol',{attrs:{"span":24}},[_c('div',{staticClass:"mb-20 f13"},[_c('aSwitch',{attrs:{"size":"small"},on:{"change":_vm.onChangeIsInternational},model:{value:(_vm.isInternational),callback:function ($$v) {_vm.isInternational=$$v},expression:"isInternational"}}),_vm._v(" "),_c('span',{staticClass:"relative ml-5",staticStyle:{"top":"2px"}},[_vm._v("Cliente internacional")])],1),_vm._v(" "),_c('aFormItem',{staticClass:"none"},[_c('aInput',{directives:[{name:"decorator",rawName:"v-decorator",value:(['is_international']),expression:"['is_international']"}]})],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":24}},[_c('HayaTextField',{attrs:{"label":"Nome","fieldKey":"first_name","required":true}})],1),_vm._v(" "),_c('aCol',{attrs:{"span":24}},[_c('HayaTextField',{attrs:{"label":"Sobrenome","fieldKey":"last_name","required":true}})],1),_vm._v(" "),_c('aCol',{attrs:{"span":24}},[_c('HayaTextField',{attrs:{"label":"E-mail","fieldKey":"email","required":true,"rules":[
          {
            type: 'email',
            message: 'Escreva um e-mail válido',
          },
        ]}})],1),_vm._v(" "),_c('aCol',{attrs:{"span":24}},[_c('HayaMaskedTextField',{attrs:{"label":"CPF","fieldKey":"cpf","vMask":!_vm.isInternational ? '###.###.###-##' : '####################',"required":true}})],1),_vm._v(" "),_c('aCol',{attrs:{"span":8}},[_c('HayaSelectField',{attrs:{"label":"Celular","fieldKey":"country_code_fake","initialValue":"Brasil-55","showSearch":true,"allowClear":true,"dropdownMatchSelectWidth":false,"optionFilterProp":'txt',"list":_vm.countryCodes.map((item) => {
            return {
              label: `
              <img width='20' src='${item.img}' alt='flag' class='mr-5' />
              <b>+${item.ddi}</b>
              <span class='ml-10 f11' style='color: #aaa'>${item.pais}</span>
               `,
              value: `${item.pais}-${item.ddi}`,
            };
          }),"required":true},on:{"onChangeSelectField":_vm.onChangeCountryCode}}),_vm._v(" "),_c('aFormItem',{staticClass:"none"},[_c('aInput',{directives:[{name:"decorator",rawName:"v-decorator",value:(['country_code', { initialValue: '55' }]),expression:"['country_code', { initialValue: '55' }]"}],staticClass:"travel-input"})],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":16}},[_c('HayaMaskedTextField',{attrs:{"label":"Celular","fieldKey":"mobile_phone","vMask":!_vm.isInternational ? '(##) # ####-####' : '############',"required":true,"rules":[
          {
            required: true,
            min: 16,
            message: 'Preencha todos números',
          },
        ]}})],1),_vm._v(" "),_c('aCol',{staticClass:"a-center",attrs:{"span":24}},[_c('aButton',{attrs:{"type":"primary","loading":_vm.loadingSubmitCustomer,"html-type":"submit"}},[_vm._v("\n        Cadastrar\n      ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }