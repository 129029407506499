<template>
  <aForm @submit.prevent="submitCustomer" :form="customerForm">
    <aRow :gutter="20">
      <aCol :span="24">
        <div class="mb-20 f13">
          <aSwitch
            size="small"
            v-model="isInternational"
            @change="onChangeIsInternational"
          />
          <span class="relative ml-5" style="top: 2px"
            >Cliente internacional</span
          >
        </div>

        <aFormItem class="none">
          <aInput v-decorator="['is_international']" />
        </aFormItem>
      </aCol>

      <aCol :span="24">
        <HayaTextField label="Nome" fieldKey="first_name" :required="true" />
      </aCol>

      <aCol :span="24">
        <HayaTextField
          label="Sobrenome"
          fieldKey="last_name"
          :required="true"
        />
      </aCol>

      <aCol :span="24">
        <HayaTextField
          label="E-mail"
          fieldKey="email"
          :required="true"
          :rules="[
            {
              type: 'email',
              message: 'Escreva um e-mail válido',
            },
          ]"
        />
      </aCol>

      <aCol :span="24">
        <HayaMaskedTextField
          label="CPF"
          fieldKey="cpf"
          :vMask="!isInternational ? '###.###.###-##' : '####################'"
          :required="true"
        />
      </aCol>

      <aCol :span="8">
        <HayaSelectField
          label="Celular"
          fieldKey="country_code_fake"
          initialValue="Brasil-55"
          :showSearch="true"
          :allowClear="true"
          :dropdownMatchSelectWidth="false"
          :optionFilterProp="'txt'"
          :list="
            countryCodes.map((item) => {
              return {
                label: `
                <img width='20' src='${item.img}' alt='flag' class='mr-5' />
                <b>+${item.ddi}</b>
                <span class='ml-10 f11' style='color: #aaa'>${item.pais}</span>
                 `,
                value: `${item.pais}-${item.ddi}`,
              };
            })
          "
          :required="true"
          @onChangeSelectField="onChangeCountryCode"
        />

        <aFormItem class="none">
          <aInput
            class="travel-input"
            v-decorator="['country_code', { initialValue: '55' }]"
          />
        </aFormItem>
      </aCol>

      <aCol :span="16">
        <HayaMaskedTextField
          label="Celular"
          fieldKey="mobile_phone"
          :vMask="!isInternational ? '(##) # ####-####' : '############'"
          :required="true"
          :rules="[
            {
              required: true,
              min: 16,
              message: 'Preencha todos números',
            },
          ]"
        />
      </aCol>

      <aCol class="a-center" :span="24">
        <aButton
          type="primary"
          :loading="loadingSubmitCustomer"
          html-type="submit"
        >
          Cadastrar
        </aButton>
      </aCol>
    </aRow>
  </aForm>
</template>

<script>
import countryCodes from "@/json/countryCodeList";

import HayaTextField from "../../general/fields/HayaTextField.vue";
import HayaSelectField from "../../general/fields/HayaSelectField.vue";
import HayaMaskedTextField from "../../general/fields/HayaMaskedTextField.vue";

export default {
  name: "CustomerBasicForm",
  components: { HayaTextField, HayaMaskedTextField, HayaSelectField },
  data() {
    return {
      customerForm: this.$form.createForm(this),
      loadingSubmitCustomer: false,
      isInternational: false,
      countryCodes,
    };
  },
  mounted() {
    this.customerForm.setFieldsValue({
      is_international: 0,
    });
  },
  methods: {
    onChangeIsInternational(e) {
      this.customerForm.setFieldsValue({
        is_international: e ? "1" : "0",
      });
    },
    onChangeCountryCode(e) {
      this.customerForm.setFieldsValue({
        country_code: e ? e.split("-")[1] : "",
      });
    },
    submitCustomer() {
      this.customerForm.validateFields(async (err, customer) => {
        if (!err) {
          customer.id = this.$route.params.id;
          customer.user_id = this.$store.state.userData.id;
          customer.status = "Ativo";
          customer.person_type = "Pessoa Física";

          this.loadingSubmitCustomer = true;

          this.$http
            .post("/customer/create", customer)
            .then(({ data }) => {
              this.$message.success(data.message);
              this.$emit("onCreateCustomer", data);
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
            })
            .finally(() => {
              this.loadingSubmitCustomer = false;
            });
        }
      });
    },
  },
};
</script>
